h1 {
  margin: 0;
}

p,
h3 {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.3em;
}

p {
  /* border-bottom: 2px solid black; */
  padding-bottom: 0.6em;
  margin-left: 2rem;
}

a {
  color: #558779;
  display: inline-block;
  text-decoration: none;
}
a:hover {
  color: black;
}

.a--underline {
  padding-bottom: 0.3em;
  border-bottom: 2px solid #558779;
}

.a--underline:hover {
  border-bottom: 2px solid black;
}

.App {
  position: relative;
}

.App-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App svg {
  width: 100%;
  height: auto;
}

.App-title {
  position: absolute;
  top: 0;
  left: 0;

  text-transform: uppercase;
  letter-spacing: 0.15em;
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 1;
  font-size: 7vmax;
}

.App-text {
  position: absolute;
  width: 80%;
  max-width: 17rem;
  top: 17%;
  right: 4%;
}

.App-text--inner {
  background-color: rgba(255, 255, 255, 0.97);
  padding: 2rem;
}

html,
body,
#root,
.App,
.App-bg {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}
